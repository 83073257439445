<template>
  <div class="dom_box">
    <div class="barnner">
      <img :src="list[imgIndex].url" />
    </div>
    <div style="border-bottom: 1px solid #d8d8d8">
      <div class="box1">
        <div class="nav_">
          <div
            v-for="item in list"
            :key="item.id"
            class="from_list"
            @click="from_btn(item.id)"
            :class="{ click_sty: clickId == item.id }"
          >
            {{ item.name }}
            <div
              v-if="clickId == item.id"
              style="position: relative; top: -4px"
            >
              <div style="background: #941c24; height: 4px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="all">
        <div class="haed">
          <div class="titleS">
            新闻聚焦 &nbsp;|&nbsp;{{ contentList.newsTitle }}
          </div>
          <div class="smallTime">
            {{ clickName }}&nbsp; | &nbsp;{{ contentList.publishTime }}
          </div>
        </div>
        <div class="body">
          <div class="image">
            <!-- //<img :src="this.$route.params.v[0].img" alt="" /> -->
            <img :src="contentList.newsCover" style="width: 100%" />
            <!-- <img src="@/assets/image/img/新闻默认图.jpg" alt="" style="width:100%" /> -->
          </div>
          <!-- 新闻详情视频模块 -->
          <div
            style="
              width: 100%;
              background-color: #000;
              margin-bottom: 20px;
              position: relative;
              margin-left: auto;
              margin-right: auto;
            "
            v-if="contentList.videoUrl"
          >
            <video
              class="video-js vjs-default-skin videoNode"
              :src="contentList.videoUrl"
              ref="videoNode"
              :poster="contentList.videoImage"
              data-setup="{}"
            ></video>
          </div>
          <!-- <p class="imgCentent">这里到时候填写图片信息</p> -->
          <div class="show-content" v-html="contentList.newsContent"></div>
        </div>
      </div>
      <div class="foot">
        <div
          style="
            text-align: center;
            font-size: 40px;
            font-weight: 500;
            line-height: 56px;
          "
        >
          相关新闻
        </div>
        <centerS style="margin-bottom: 60px"></centerS>
        <div class="footImg">
          <div
            v-for="item in footList"
            @click="junp(item.newsId, item)"
            :key="item.newsId"
            class="title_btn"
          >
            <div
              style="
                text-align: center;
                position: relative;
                height: 196px;
                overflow: hidden;
              "
            >
              <div class="list_type">
                {{ clickName }}
              </div>
              <img v-if="item.newsCover" :src="item.newsCover" />

              <img
                v-else
                src="@/assets/image/img/新闻默认图.jpg"
                alt=""
                style="width: 100%"
              />
            </div>
            <div style="margin: 25px">
              <div>
                <!-- //:class="{ title_sty: title_id == list.id }" -->
                {{
                  item.newsTitle.length > 25
                    ? item.newsTitle.substring(0, 25) + "..."
                    : item.newsTitle
                }}
              </div>
              <div
                style="
                  display: flex;
                  font-size: 14px;
                  color: #181818;
                  margin: 25px 0;
                "
              >
                <div>{{ clickName }}</div>
                &nbsp;|&nbsp;
                <div style="margin-top: 1px">
                  {{ item.publishTime }}
                </div>
              </div>
              <div class="details">
                {{ item.newsAbstract }}
              </div>
            </div>
          </div>
          <!-- // -->
        </div>
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 20px;
            margin-bottom: 100px;
          "
        >
          <el-button size="medium" @click="lookMore" round class="button">
            查看更多
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </div>
    </div>
    <division></division>
  </div>
</template>

<script>
import axios from "axios";
import videojs from "video.js";
import "video.js/dist/video-js.css";
export default {
  name: "DetailPage",
  data() {
    return {
      content3: [],
      contentList: {},
      footList: [],
      clickId: 17,
      clickName: "集团要闻",
      list: [
        {
          id: 17,
          lastId: 11,
          name: "集团要闻",
          url: require("../../../static/newImage/位图 6.png"),
        },
        {
          id: 16,
          lastId: 12,
          name: "产业动态",
          url: require("../../../static/newImage/位图 5.png"),
        },
        {
          id: 15,
          lastId: 13,
          name: "媒体报道",
          url: require("../../../static/newImage/位图 4.png"),
        },
        {
          id: 27,
          lastId: 29,
          name: "政府支持",
          url: require("../../../static/newImage/位图 3.png"),
        },
        {
          id: 28,
          lastId: 30,
          name: "社会贡献",
          url: require("../../../static/newImage/位图 2.png"),
        },
        {
          id: 24,
          lastId: 31,
          name: "热点专题",
          url: require("../../../static/newImage/位图.png"),
        },
        {
          id: 22,
          lastId: 32,
          name: "集团新闻",
          url: require("../../../static/newImage/位图 7.png"),
        },
      ],
      record: null,
      dot_id: null,
      imgIndex: 0,
      player: null,
    };
  },

  mounted() {
    window.scrollTo(0, 0);
    this.clickId = this.$route.query.websiteCategoryId;
    const obj = this.list.filter((val) => {
      return val.id == this.clickId;
    });
    this.clickName = obj[0].name;
    this.getDetail(this.$route.query.v);
    this.getMoreList();
  },

  methods: {
    //查看更多
    lookMore() {
      this.$router.push({
        path: "/press_center",
        query: { websiteCategoryId: this.clickId },
      });
    },
    from_btn(v) {
      this.clickId = v;
      this.$router.push({
        path: "/press_center",
        query: { websiteCategoryId: this.clickId },
      });
    },
    junp(id) {
      this.$router.push({
        path: "/press_center_DetailPage",
        query: { v: id, websiteCategoryId: this.clickId },
      });
      window.scrollTo(0, 500);
      this.getDetail(id);
      this.getMoreList();
    },
    getDetail(v) {
      axios
        .get(`https://t2.zyjkjt.net/cms/api/app/news/${v}`, {})
        .then((res) => {
          this.record = res.data.data.newsId;
          this.contentList = res.data.data;
          this.$nextTick(() => {
            this.player = videojs(this.$refs.videoNode, {
              preload: "auto",
              controls: true,
            });
            const name =
              document.getElementsByClassName("vjs-poster")[1].childNodes[0];
            name.src = this.contentList.videoImage;
          });
        });
    },
    getMoreList() {
      axios
        .get(
          `https://t2.zyjkjt.net/cms/api/app/news/list?current=1&size=4&websiteCategoryId=${this.clickId}`
        )
        .then((res) => {
          const x = res.data.data.list.filter(
            (item) => item.newsId !== this.record
          );
          this.footList = x.slice(0, 3);
        });
    },
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  watch: {
    clickId: function (val) {
      this.list.forEach((val1, index) => {
        if (val1.id == val) {
          this.imgIndex = index;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.dom_box {
  min-width: 1600px;
}
p {
  margin: 0;
  padding: 0;
}

.all {
  // padding: 0 330px;
  max-width: 1200px;
  margin: 0 auto;
}

.haed {
  margin-top: 55px;
  border-bottom: 1px solid #d8d8d8;
}

.titleS {
  display: flex;
  font-size: 35px;
  font-weight: 500;
  line-height: 66px;
  margin-bottom: 24px;
}

.titleS > p {
  font-size: 40px;
  display: inline-block;
  color: red;
}

.smallTime {
  font-size: 14px;
  margin-bottom: 24px;
}

.footnew {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.body {
  // margin: 0;
  max-width: 1220px;
  margin: 20px auto;
  font-size: 18px;
  font-weight: 400;
  color: rgba(24, 24, 24, 0.85);
  line-height: 25px;
}
::v-deep .body p img {
  width: 100%;
}

.footnew > div:nth-child(3) {
  margin: 0 30px;
  margin-bottom: 24px;
}

.image {
  /* width: 740px;
  height: 440px; */
  /* background: pink; */
  text-align: center;
  margin: 24px auto;
}

.imgCentent {
  text-align: center;
  margin-bottom: 24px;
}

.foot {
  margin: 0 auto;
  margin-top: 120px;
  font-size: 22px;
  max-width: 1500px;
}

.footImg {
  display: flex;
  font-size: 16px;
  margin-bottom: 32px;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 1500px;
}

@media screen and (max-device-width: 1050px) {
  .footImg {
    width: 1000px;
    margin: 0 auto;
  }
}

.title_btn {
  cursor: pointer;
  width: calc(33.3% - 40px);
  height: 424px;
  background-color: #f8f8f8;
  margin: 10px 20px;
}

.title_btn img {
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.list_type {
  color: #fff;
  position: absolute;
  padding: 7px 20px;
  background: #ddb563;
  z-index: 9;
}

.details {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.button {
  width: 144px;
  height: 44px;
  border-radius: 22px;
  border: 1px solid #e1251b;
  color: #e1251b;
}

.list_nav {
  display: inline-block;
  padding: 7px 24px;
  position: absolute;
  color: #fff;
  background-color: #c5a360;
}

.img_list1_sty {
  width: 400px;
  height: 450px;
  cursor: pointer;
  margin-bottom: 30px;
  background: #f8f8f8;
}

.list_content_sty {
  font-size: 14px;
  margin-bottom: 20px;
  color: #181818;
}

/*  */
.barnner {
  background-image: url("../../../static/newImage/位图0.jpg");
  width: 100%;
  height: 550px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  position: relative;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 140px;
  }
}

.box {
  height: 100%;
  margin: 0 auto;
  max-width: 1600px;
  .nav_ {
    max-width: 1600px;
    height: 70px;
    display: flex;
    // padding: 0 210px;
    font-size: 20px;
    line-height: 70px;
    justify-content: space-around;
    // border-bottom: 1px solid #d8d8d8;

    .from_list {
      // width: 245px;
      width: 135px;
      text-align: center;
      cursor: pointer;
      font-size: 24px;
      /* 更改鼠标样式为手 */
      position: relative;
    }
    .click_sty {
      color: #c51722;
      font-size: 28px;
    }
  }
}
.box1 {
  height: 100%;
  margin: 0 auto;
  max-width: 1600px;
  .nav_ {
    max-width: 1600px;
    height: 70px;
    display: flex;
    // padding: 0 210px;
    font-size: 20px;
    line-height: 70px;
    justify-content: space-around;
    // border-bottom: 1px solid #d8d8d8;

    .from_list {
      // width: 245px;
      width: 135px;
      text-align: center;
      cursor: pointer;
      font-size: 24px;
      /* 更改鼠标样式为手 */
      position: relative;
    }
    .click_sty {
      color: #c51722;
      font-size: 28px;
    }
  }
}
.from_list {
  width: 245px;
  text-align: center;
  cursor: pointer;
  /* 更改鼠标样式为手 */
  position: relative;
}

.click_sty {
  color: red;
}

.input_sty {
  width: 260px;
  height: 40px;
}
::v-deep .video-js {
  width: 100% !important;
  max-height: 500px;
}
</style>
